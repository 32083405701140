
























































































interface IsearchData {
  name: string;
  state: string;
}
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  GetInstitutionArticleList,
  InstitutionArticleState,
  InstitutionArticleDel,
} from '@/http/api/InstitutionApi';

@Component
export default class Material extends Vue {
  @Prop() private msg!: string;
  private loading: boolean = false;
  private searchData: IsearchData = {
    name: '',
    state: '',
  };
  private totalPage: number = 0; // 总页数
  private totalCount: number = 0; // 总条数
  private pageNo: number = 1; // 当前页数
  private pageSize: number = 10; // 每页几条
  private articleList: any[] = [];
  private created() {
    this.getList();
  }
    // 发布+置顶
  private setUpState(id: string, val: number) {
    const params: any = {
      id,
    };
    let str: string = '';
    params.status = val;
    if (val === 1) {
      str = '发布';
    } else {
      str = '取消发布';
    }
    this.$confirm(`确定要${str}吗？`, '确认', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      this.loading = true;
      InstitutionArticleState(params).then((res) => {
        if (res.code === '000') {
          this.$message.success('操作成功');
          this.getList();
        }
      });
    });
  }
  // 删除文章
  private del(id: string) {
    this.$confirm(`确定要删除这篇文章吗？`, '确认删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      this.loading = true;
      InstitutionArticleDel({ id }).then((res) => {
        if (res.code === '000') {
          this.$message.success('删除成功');
          this.getList();
        }
      });
    });
  }
  // 获取文章数据列表
  private getList(): void {
    this.loading = true;
    GetInstitutionArticleList({
      cmsType: '7',
      keyword: this.searchData.name,
      status: this.searchData.state,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        const data = res.result;
        this.totalCount = data.total;
        this.totalPage = data.pages;
        this.pageNo = data.current;
        this.articleList = data.records;
      }
    });
  }
  private handleSizeChange(val: number): void {
    this.pageNo = 1;
    this.pageSize = val;
    this.getList();
  }
  private handleCurrentChange(val: number): void {
    this.pageNo = val;
    this.getList();
  }
  private search(): void {
    this.pageNo = 1;
    this.getList();
  }
  private reset(): void {
    this.searchData.name = '';
    this.searchData.state = '';
    this.pageNo = 1;
    this.getList();
  }
  private addItem(id?: string): void {
    const path = 'material/add';
    this.$router.push({
      path,
      query: {
        id,
      },
    });
  }
  private addItemsss():void{
      const path = 'material/add';
      this.$router.push({
        path,
    
      });
  }
}
